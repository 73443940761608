import { Button, Grid, TextField } from "@mui/material";
import WebSocket from "isomorphic-ws";
import { useCallback, useEffect, useState } from "react";

const websocketAddress =
  "wss://tp34dh1vg9.execute-api.eu-central-1.amazonaws.com/dev";

const baseApi = "https://myc9xl36yd.execute-api.eu-central-1.amazonaws.com";
const getConnectionsAddress = `${baseApi}/get-connections`;
const deleteConnectionsAddress = `${baseApi}/delete-connections`;
console.log("new WebSocket");
const websocket = new WebSocket(websocketAddress);

function SocketDebugger() {
  //const [websocket] = useState(new WebSocket(websocketAddress));
  const [websocketState, setWebsocketState] = useState("");

  const [userId, setUserId] = useState("sascha.rose@gmail.com");
  const [message, setMessage] = useState("NOTHING");

  const [connectionsContent, setConnectionsContent] = useState();

  // useEffect(() => {
  //   console.log("useEffect()");
  //   return () => {
  //     console.log("useEffect() return");
  //   };
  // }, [websocketState, userId, message, connectionsContent]);

  websocket.onopen = (e) => {
    setWebsocketState("connected");
    console.log("connected");
    console.log(e);
    //
    // SET USERID HERE:
    //
    websocket.send(
      JSON.stringify({ userId: "sascha.rose@gmail.com" }),
      (err) => {
        console.log("encountered an error");
        console.error(err);
      }
    );
  };

  // websocket.onmessage = (e) => {
  //   console.log(e);
  //   setMessage((prevMessage) => prevMessage + JSON.stringify(e.data));
  // };

  // websocket.onopen = (e) => {
  //   setWebsocketState("connected");
  //   console.log("connected");
  //   console.log(e);
  //   //
  //   // SET USERID HERE:
  //   //
  //   // websocket.send(JSON.stringify({ userId: 'sascha.rose@gmail.com' }), (err) => {
  //   //   console.log('encountered an error');
  //   //   console.error(err);
  //   // });
  // };

  // websocket.onclose = (e) => {
  //   setWebsocketState("closed");
  //   console.log("closed");
  //   console.log(e);
  // };

  // websocket.onerror = (e) => {
  //   setWebsocketState("encountered an error");
  //   console.log("encountered an error");
  //   console.error(e);
  // };

  const loadConnections = useCallback(() => {
    fetch(getConnectionsAddress)
      .then((result) => result.json())
      .then((result) => setConnectionsContent(renderConnections(result)));
  }, []);

  const deleteConnections = useCallback(() => {
    fetch(deleteConnectionsAddress)
      .then((result) => result.json())
      .then((result) => setConnectionsContent(renderConnections(result)));
  }, []);

  function renderConnections(result) {
    if (!result) {
      return <div>NO DATA</div>;
    }
    return result.map((element) => (
      <Grid key={element["_id"]} container columns={2}>
        <Grid item xs={1}>
          {element["item"]["connectionId"]}
        </Grid>
        <Grid item xs={1}>
          {element["item"]["userId"]}
        </Grid>
      </Grid>
    ));
  }

  return (
    <div>
      <hr />
      Websocket State: {websocketState}
      <hr />
      <Grid container columns={3}>
        <Grid item xs={1}>
          UserId
        </Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            defaultValue={userId}
            onChange={(e) => setUserId(e.target.value)}
          ></TextField>
        </Grid>
        <Grid item xs={1}>
          <Button
            onClick={() => {
              //websocket.send(JSON.stringify({ userId }))
            }}
          >
            Register UserId
          </Button>
        </Grid>
      </Grid>
      <hr />
      <p>Message:</p>
      <hr />
      <pre>{message}</pre>
      <hr />
      Connections:
      <hr />
      <Button onClick={() => loadConnections()}>load connections</Button>
      <Button onClick={() => setConnectionsContent(null)}>
        delete display
      </Button>
      <Button onClick={() => deleteConnections()}>
        delete connections from database
      </Button>
      <hr />
      {connectionsContent}
    </div>
  );
}

export default SocketDebugger;
